import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default.tsx";
import ImportantDetails from "../components/my-details";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I’m a frontend developer in Milwaukee, WI. I like building things with React and Typescript. Get the full details in `}<a parentName="p" {...{
        "href": "/resume"
      }}>{`my resume`}</a>{`.`}</p>
    <ImportantDetails mdxType="ImportantDetails" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      